<!-- <div *ngIf="status" class="row mx-0">
  <div class="col-12 px-0">
    <router-outlet></router-outlet>
  </div>
</div> -->

<div class="jodi-loader" *ngIf="showSpinner">
    <div class="loader"></div>
    <img src="/assets/images/meta/jodi365-logo.png" style="position:absolute;top:45vh;" alt="" height="55px" width="170px">
</div>

<router-outlet></router-outlet>